import React, {useState, useEffect} from "react"
import { graphql } from "gatsby"
import axios from 'axios'
import { Container, Grid, Button, Segment, Form, Modal, TextArea } from "semantic-ui-react"
import Layout from "../components/Layout"
import Breadcrumbs from "../components/Breadcrumbs"

export const query = graphql`
  query {
    page: strapiContact(id: { ne: "" }) {
      Title
      Name
      Email
      Phone
      Message
      Submit
      Success
      Error
    }
  }
`




const ContactPage = ({ data, location }) => {
const [name, setName] = useState()
const [email, setEmail] = useState()
const [phone, setPhone] = useState()
const [message, setMessage] = useState()
const [showSucces, setSucces]= useState(false)
const [showError, setError]= useState(false)
const [valid,setValid]=useState(false)

useEffect(()=>{
  if(!name || !phone || !validateEmail(email) ||!message){
    setValid(false)
  }
  else{
    setValid(true)
  }
},[name,email,phone,message])

function validateEmail(email){
  email = email.trim();
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase())
}

const onChange = (e,p) =>{
  switch (p.name){
    case 'name':
      setName(p.value)
      break

    case 'email':
      setEmail(p.value)
      break
    
    case 'phone':
      setPhone(p.value)
      break
    case 'message':
      setMessage(p.value)
      break

    default:
      break
  }
}
const send = (e) =>{
  e.preventDefault()
  const data ={
    Name: name,
    Email: email,
    Phone: phone,
    Message: message,
    Date: `${new Date().getDate()}-${new Date().getMonth()+1}-${new Date().getFullYear()}`
  }
  axios.post(`${process.env.GATSBY_API_URL}/contact-infos`,data)
    .then(() =>{
      setSucces(true)
    })
    .catch(error =>{
      setError(true)
    })
}

  const page = data.page
  return (
    <Layout location={location}>
      <Grid stackable>
        <Grid.Row>
          <Container className="maininner">
            <Breadcrumbs
              className="content-breadcrumbs"
              PageTitle={page.Title}
            />
            <article className="content-article">
              <header>
                <h1>{page.Title}</h1>
              </header>
              <div></div>
            </article>
            <Segment>
              <Form>
                <Form.Field>
                  <label>{page.Name}</label>
                  <Form.Input name = 'name' placeholder="Nombres y Apellidos" onChange={onChange} value = {name} />
                </Form.Field>
                <Form.Field>
                  <label>{page.Email}</label>
                  <Form.Input name = 'email' placeholder="Email" onChange={onChange}  value = {email} />
                </Form.Field>
                <Form.Field>
                  <label>{page.Phone}</label>
                  <Form.Input name = 'phone' placeholder="Número de Teléfono" onChange={onChange}  value = {phone}/>
                </Form.Field>
                <Form.Field>
                  <label>{page.Message}</label>
                  <TextArea name = 'message' placeholder='Ingrese su mensaje' onChange ={onChange} value = {message} />
                </Form.Field>
                <Button type="submit" onClick={send} disabled ={!valid}>{page.Submit}</Button>
              </Form>
              <Modal 
                open = {showSucces}
                header='Exito'
                content = {page.Success}
                actions= {[{key: 'done', content: 'Ok', positive: true, onClick:()=>setSucces(false)}]}
                closeOnDimmerClick={true}
                closeOnDocumentClick={true}
                onClose={()=>setSucces(false)}
              />
              <Modal 
                open = {showError}
                header='Error'
                content = {page.Error}
                actions= {[{key: 'done', content: 'Ok', positive: false, onClick:()=>setError(false)}]}
                closeOnDimmerClick={true}
                closeOnDocumentClick={true}
                onClose={()=>setError(false)}
              />
            </Segment>
          </Container>
        </Grid.Row>
      </Grid>
    </Layout>
  )
}

export default ContactPage
